import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Box, Flex, Hide, Button } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import InputWithAddon from 'components/InputWithAddon';
import ResponsiveModal from 'components/ResponsiveModal';
import Dropdown from 'components/Dropdown';
import Occupant from './Occupant';
import { mediaQuery } from 'lib/styledSystem';
import TextButton from 'components/TextButton';
import MultiRoomMessage from './MultiRoomMessage/MultiRoomMessage';
import TreatmentToggle from 'components/TreatmentToggle';

const MAX_OCCUPANT_COUNT = 9;
const MULTI_ROOM_MESSAGE_PROMPT_THRESHOLD = 3;

const Root = styled(Box)`
  position: relative;
  user-select: none;
`;

const InputWrapper = styled(Box)`
  ${(props) =>
    props.isOpen &&
    css`
      display: none;

      ${mediaQuery.minWidth.sm(props)} {
        display: block;
      }
    `};
`;

const OccupantInput = styled(InputWithAddon)`
  &:focus {
    border-color: ${themeGet('colors.ui.linkFocus')};
  }
`;

const StandardOccupantInput = styled(InputWithAddon)`
  padding-left: 44px !important;
  padding-right: 12px !important;

  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.5;
`;

const MobileOccupantInput = styled(StandardOccupantInput)`
  &:focus {
    border-color: ${themeGet('colors.ui.linkFocus')};
  }
`;

const Actions = styled(Box)`
  position: fixed;
  padding: ${themeGet('space.4')};
  text-align: right;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    border: 1px solid ${themeGet('colors.greys.alto')};
  }
`;

const UpdatedActions = styled(Actions)`
  padding: ${themeGet('space.4')} ${themeGet('space.8')};

  ${mediaQuery.minWidth.sm} {
    border: none;
    border-top: 1px solid ${themeGet('colors.greys.alto')};
  }
`;

const OccupantPicker = ({ occupants, labelOptions, updateQuery, viewThreshold, verboseInMobile, multiroomEnabled }) => {
  const [adults, setAdults] = useState(occupants.adults ?? 2);
  const [children, setChildren] = useState(occupants.children ?? 0);
  const [infants, setInfants] = useState(occupants.infants ?? 0);

  const occupantCount = adults + children + infants;
  const summaryText = pluralize('guest', occupantCount, true);

  const isDirty = adults !== occupants.adults || children !== occupants.children || infants !== occupants.infants;
  const showMultiRoomMessage = multiroomEnabled && adults > MULTI_ROOM_MESSAGE_PROMPT_THRESHOLD;

  const updateGuests = useCallback(() => {
    setAdults(occupants.adults ?? 2);
    setChildren(occupants.children ?? 0);
    setInfants(occupants.infants ?? 0);
  }, [occupants.adults, occupants.children, occupants.infants]);

  const apply = () => {
    if (isDirty) {
      updateQuery({ adults, children, infants });
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      updateGuests();
    }
  };

  useEffect(() => {
    updateGuests();
  }, [occupants, updateGuests]);

  const incrementAdults = useCallback(() => setAdults(adults + 1), [adults]);
  const decrementAdults = useCallback(() => setAdults(adults - 1), [adults]);

  const incrementChildren = useCallback(() => setChildren(children + 1), [children]);
  const decrementChildren = useCallback(() => setChildren(children - 1), [children]);

  const incrementInfants = useCallback(() => setInfants(infants + 1), [infants]);
  const decrementInfants = useCallback(() => setInfants(infants - 1), [infants]);

  return (
    <>
      <TreatmentToggle split="jh_header_nav" treatment="off">
        <ResponsiveModal title="Select guests" onSubmit={apply} onBlur={apply} onCancel={handleCancel}>
          {({ isOpen, openModal, submitModal, cancelModal }) => (
            <Fragment>
              <Root data-testid="occupant-picker">
                <InputWrapper isOpen={isOpen}>
                  <Hide xs>
                    <Label position="relative">
                      <LabelText {...labelOptions} color="greys.charcoal" fontWeight="normal" fontSize="sm">
                        Guests
                      </LabelText>
                      <InputWithAddon
                        data-testid="occupant-picker-input"
                        borderRadius="default"
                        icon="person"
                        value={summaryText}
                        onFocus={openModal}
                        isHighlighted={isOpen}
                        border={2}
                        readonly
                        cursor="pointer"
                      />
                    </Label>
                  </Hide>
                  <Hide sm md lg>
                    <Label>
                      <LabelText {...labelOptions} hidden={[true, false]} color="greys.charcoal" fontWeight="normal">
                        Guests
                      </LabelText>
                      <OccupantInput
                        borderRadius="default"
                        icon="person"
                        value={verboseInMobile ? summaryText : occupantCount}
                        onClick={openModal}
                        onKeyUp={({ keyCode }) => {
                          if (keyCode === 13) openModal();
                        }}
                        isHighlighted={isOpen}
                        readonly
                        border={[1, 2, 2]}
                        cursor="pointer"
                      />
                    </Label>
                  </Hide>
                </InputWrapper>
                {isOpen && (
                  <Dropdown
                    anchorX="right"
                    width={['100%', '194px', '388px']}
                    viewThreshold={viewThreshold}
                    skipToContent="#main-content"
                    cancelModal={cancelModal}
                  >
                    <Flex flexWrap="wrap">
                      <Box width={[1, 1, 0.5]}>
                        <Occupant
                          data-testid="occupant-picker-adults"
                          label="Adults"
                          count={adults}
                          increment={incrementAdults}
                          decrement={decrementAdults}
                          min={1}
                          max={MAX_OCCUPANT_COUNT}
                        />
                      </Box>
                      <Box width={[1, 1, 0.5]}>
                        <Occupant
                          data-testid="occupant-picker-children"
                          label="Children"
                          ages="2 - 12yrs"
                          count={children}
                          increment={incrementChildren}
                          decrement={decrementChildren}
                          min={0}
                          max={MAX_OCCUPANT_COUNT}
                        />
                      </Box>
                      <Box width={[1, 1, 0.5]}>
                        <Occupant
                          data-testid="occupant-picker-infants"
                          label="Infants"
                          ages="< 2yrs"
                          count={infants}
                          increment={incrementInfants}
                          decrement={decrementInfants}
                          min={0}
                          max={MAX_OCCUPANT_COUNT}
                        />
                      </Box>
                    </Flex>

                    {showMultiRoomMessage && <MultiRoomMessage />}

                    <Actions>
                      <Hide xs>
                        <TextButton fontSize="base" textDecoration="none" data-testid="done-button" onClick={submitModal}>
                          Done
                        </TextButton>
                      </Hide>
                    </Actions>
                  </Dropdown>
                )}
              </Root>
            </Fragment>
          )}
        </ResponsiveModal>
      </TreatmentToggle>
      <TreatmentToggle split="jh_header_nav" treatment="on">
        <ResponsiveModal title="Select guests" onSubmit={apply} onBlur={apply} onCancel={handleCancel} submitButtonText="Confirm">
          {({ isOpen, openModal, submitModal, cancelModal }) => (
            <Fragment>
              <Root data-testid="occupant-picker">
                <InputWrapper isOpen={isOpen}>
                  <Hide xs>
                    <Label position="relative">
                      <LabelText {...labelOptions}>Guests</LabelText>
                      <StandardOccupantInput
                        data-testid="occupant-picker-input"
                        borderRadius="default"
                        icon="person"
                        value={summaryText}
                        onFocus={openModal}
                        isHighlighted={isOpen}
                        border={2}
                        readonly
                        cursor="pointer"
                      />
                    </Label>
                  </Hide>
                  <Hide sm md lg>
                    <Label>
                      <LabelText {...labelOptions} hidden={[true, false]}>
                        Guests
                      </LabelText>
                      <MobileOccupantInput
                        borderRadius="default"
                        icon="person"
                        value={verboseInMobile ? summaryText : occupantCount}
                        onClick={openModal}
                        onKeyUp={({ keyCode }) => {
                          if (keyCode === 13) openModal();
                        }}
                        isHighlighted={isOpen}
                        readonly
                        border={2}
                        cursor="pointer"
                      />
                    </Label>
                  </Hide>
                </InputWrapper>
                {isOpen && (
                  <Dropdown
                    anchorX="right"
                    width={['100%', '423px']}
                    viewThreshold={viewThreshold}
                    skipToContent="#main-content"
                    cancelModal={cancelModal}
                    style={{ paddingTop: 0, background: '#ffffff', borderRadius: '0.625rem' }}
                  >
                    <Flex flexDirection="column" alignItems="stretch" px={[4, 8]} pb={6} pt={[0, 6]} style={{ gap: '1rem' }}>
                      <Occupant
                        data-testid="occupant-picker-adults"
                        label="Adults"
                        count={adults}
                        increment={incrementAdults}
                        decrement={decrementAdults}
                        min={1}
                        max={MAX_OCCUPANT_COUNT}
                      />
                      <Occupant
                        data-testid="occupant-picker-children"
                        label="Children"
                        ages="2 - 12yrs"
                        count={children}
                        increment={incrementChildren}
                        decrement={decrementChildren}
                        min={0}
                        max={MAX_OCCUPANT_COUNT}
                      />
                      <Occupant
                        data-testid="occupant-picker-infants"
                        label="Infants"
                        ages="< 2yrs"
                        count={infants}
                        increment={incrementInfants}
                        decrement={decrementInfants}
                        min={0}
                        max={MAX_OCCUPANT_COUNT}
                      />
                      {showMultiRoomMessage && <MultiRoomMessage />}
                    </Flex>

                    <UpdatedActions>
                      <Hide xs>
                        <Button
                          variant="secondary"
                          py="2"
                          px="8"
                          style={{ fontSize: '.875rem', lineHeight: '1.25rem' }}
                          data-testid="done-button"
                          onClick={submitModal}
                        >
                          Confirm
                        </Button>
                      </Hide>
                    </UpdatedActions>
                  </Dropdown>
                )}
              </Root>
            </Fragment>
          )}
        </ResponsiveModal>
      </TreatmentToggle>
    </>
  );
};

OccupantPicker.propTypes = {
  occupants: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }),
  labelOptions: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  viewThreshold: PropTypes.number,
  verboseInMobile: PropTypes.bool,
  multiroomEnabled: PropTypes.bool,
};

OccupantPicker.defaultProps = {
  occupants: {
    adults: 0,
    children: 0,
    infants: 0,
  },
  labelOptions: {},
  viewThreshold: 0.5,
  verboseInMobile: true,
  multiroomEnabled: true,
};

export default OccupantPicker;
