import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { addDays, endOfMonth, format as dateFnsFormat } from 'date-fns';
import { SEARCH_DATE_FORMAT } from 'config';
import { mediaQuery } from 'lib/styledSystem';
import { Flex, Box, Text } from '@qga/roo-ui/components';
import CalendarKey from '../CalendarKey';
import { CalendarDay, CalendarEmptyDay } from '../CalendarDay';
import { InView } from 'react-intersection-observer';
import { rem } from 'polished';
import capitalize from 'lodash/capitalize';

const MonthWrapper = styled(Box)`
  text-align: center;
  padding: 0 ${themeGet('space.4')};
  width: 100%;

  ${mediaQuery.minWidth.md} {
    width: ${rem('354px')};
  }
`;

const CalendarDaysWrapper = styled(Flex)`
  flex-wrap: wrap;
`;

MonthWrapper.displayName = 'MonthWrapper';

const CalendarMonth = React.forwardRef(
  (
    {
      month,
      monthName,
      year,
      weekdayNames,
      weeks,
      getDateProps,
      onMouseEnterOfDay,
      getCustomDateProps,
      onChangeMonth,
      onEnteredView,
      isLimited,
    },
    ref,
  ) => {
    const onMonthInView = useCallback(
      (inView) => {
        if (inView) {
          const currentMonthDate = new Date(year, month, 1);
          const startOfMonthString = dateFnsFormat(currentMonthDate, SEARCH_DATE_FORMAT);
          const endOfMonthString = dateFnsFormat(endOfMonth(currentMonthDate), SEARCH_DATE_FORMAT);
          onEnteredView({ startOfMonthString, endOfMonthString });
        }
      },
      [month, year, onEnteredView],
    );

    const monthRef = useRef();
    const onKeyDown = useCallback(
      (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
          // Prevent scroll up or down the entire page
          event.preventDefault();
        } else {
          return;
        }

        const activeDateElement = document.activeElement?.getAttribute('data-time');
        if (!activeDateElement) {
          return;
        }

        const currentDate = new Date(activeDateElement);
        const change = {
          ArrowUp: -7,
          ArrowDown: 7,
          ArrowLeft: -1,
          ArrowRight: 1,
        }[event.key];
        const newDate = addDays(currentDate, change);

        const currentMonth = currentDate.getMonth();
        const newMonth = newDate.getMonth();

        if (currentMonth !== newMonth) {
          onChangeMonth(newDate);
        } else {
          // eslint-disable-next-line no-unused-expressions
          monthRef.current.querySelector(`[data-time='${dateFnsFormat(newDate, SEARCH_DATE_FORMAT)}']`)?.focus();
        }
      },
      [onChangeMonth],
    );

    return (
      <InView onChange={onMonthInView} as={MonthWrapper} data-testid="calendar-month" data-date={`${year}-${month + 1}-01`}>
        <Text
          as="div"
          fontSize="md"
          lineHeight={1.2}
          fontWeight="bold"
          mb={[4, 5]}
          mt={[0, 1]}
          style={{ scrollMarginTop: '180px' }}
          ref={ref}
        >
          {capitalize(`${monthName} ${year}`)}
        </Text>
        <CalendarKey display="flex" month={month} year={year} weekdayNames={weekdayNames} mb={3} />
        <CalendarDaysWrapper ref={monthRef} onKeyDown={onKeyDown}>
          {weeks.map((week) =>
            week.map((day, index) => {
              if (!day) return <CalendarEmptyDay key={`${year}${month}${index}`} />;
              const onMouseEnter = () => onMouseEnterOfDay(day);
              const customProps = getCustomDateProps(day);

              return (
                <CalendarDay
                  isLimited={isLimited}
                  key={`${year}${month}${index}`}
                  {...getDateProps({ dateObj: day, onMouseEnter, ...customProps })}
                >
                  {day.date.getDate()}
                </CalendarDay>
              );
            }),
          )}
        </CalendarDaysWrapper>
      </InView>
    );
  },
);

MonthWrapper.defaultProps = {
  ...Box.defaultProps,
};

CalendarMonth.displayName = 'CalendarMonth';

CalendarMonth.defaultProps = {
  getCustomDateProps: () => {},
  onMouseEnterOfDay: () => {},
  onChangeMonth: () => {},
  onEnteredView: () => {},
};

CalendarMonth.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  monthName: PropTypes.string.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.array).isRequired,
  weekdayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  getDateProps: PropTypes.func.isRequired,
  onMouseEnterOfDay: PropTypes.func,
  getCustomDateProps: PropTypes.func,
  onChangeMonth: PropTypes.func,
  onEnteredView: PropTypes.func,
  isLimited: PropTypes.bool,
};

export default CalendarMonth;
