import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDayzed } from 'dayzed';
import { subDays, isSameDay, differenceInDays, differenceInCalendarMonths } from 'lib/date/datefns';
import findIndex from 'lodash/findIndex';
import throttle from 'lodash/fp/throttle';
import get from 'lodash/get';
import { Flex, Box } from '@qga/roo-ui/components';
import { format as dateFnsFormat, isValid } from 'date-fns';
import CalendarNav from '../CalendarNav';
import CalendarMonth from '../CalendarMonth';
// import CalendarKey from '../CalendarKey';
import isDateInRange from '../lib/isDateInRange';
import { FOCUS } from '../constants';
import { format as formatDate } from 'lib/date';
import { SEARCH_DATE_FORMAT } from 'config';
import { useDataLayer } from 'hooks/useDataLayer';
import { getCalendarStays } from 'store/calendar/calendarSelectors';
// import { rem } from 'polished';
import { getIsExclusive } from 'store/property/propertySelectors';
import { getExclusiveOfferRoomTypes } from 'store/exclusiveOffer/exclusiveOfferSelectors';
import { useMediaQuery } from 'react-responsive';
import { theme } from '@qga/roo-ui';

const MS_IN_A_DAY = 86400000;
const throttled = throttle(10);

const Calendar = ({
  startDate,
  endDate,
  monthNames,
  weekdayNames,
  monthsToDisplay,
  initialDisplayDate,
  focusedInput,
  onChangeDates,
  maxSelectableDays,
  firstDayOfWeek,
  minDate,
  boundaries,
  onMonthScrolled,
  isLimited,
}) => {
  const calendarRef = useRef();
  const focusDate = useRef();
  const selectedMonth = useRef();
  const { emitInteractionEvent } = useDataLayer();
  const [hoveredDate, setHoveredDate] = useState(null);
  const [maxAvailableDays, setMaxAvailableDays] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoints[0]})` });
  const [offset, setOffset] = useState(differenceInCalendarMonths(startDate || endDate || initialDisplayDate, initialDisplayDate));

  useEffect(() => {
    if (!startDate && !endDate) setOffset(0);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isMobile && selectedMonth.current) {
      selectedMonth.current.scrollIntoView({
        block: 'start',
        behavior: 'instant',
      });
    }
  }, [isMobile, selectedMonth]);

  const selectedDates = [startDate, endDate];
  const isExclusive = useSelector(getIsExclusive);
  const exclusiveOfferRoomTypes = useSelector(getExclusiveOfferRoomTypes);

  const availabilityStays = useSelector(getCalendarStays);

  const isSettingStartDate = useCallback(() => focusedInput === FOCUS.START_DATE, [focusedInput]);

  const onOffsetChanged = useCallback((offset) => setOffset(offset), []);

  const isSettingEndDate = useCallback(() => focusedInput === FOCUS.END_DATE, [focusedInput]);

  const onMouseLeaveOfCalendar = useCallback(() => setHoveredDate(null), []);

  useEffect(() => {
    if (focusDate.current) {
      const currentDateElement = calendarRef.current.querySelector(`[data-time='${dateFnsFormat(focusDate.current, SEARCH_DATE_FORMAT)}']`);
      if (currentDateElement) {
        currentDateElement.focus();
      }
      focusDate.current = undefined;
    }
  }, [offset]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseEnterOfDay = useCallback(
    throttled((hovered) => {
      if (startDate && !endDate && hovered !== hoveredDate) {
        setHoveredDate(hovered.date);
      }
    }),
    [startDate, endDate, hoveredDate],
  );

  const resetWithStartDate = useCallback(
    (startDate) => {
      onChangeDates({ startDate, endDate: null });
    },
    [onChangeDates],
  );

  const setMaxAvailability = useCallback(
    (startDate) => {
      const time = dateFnsFormat(startDate, SEARCH_DATE_FORMAT);
      const startAvail = findIndex(availabilityStays, ['date', time]);
      const firstUnavail = findIndex(availabilityStays, ['available', false], startAvail);
      if (firstUnavail > startAvail) {
        setMaxAvailableDays(firstUnavail - startAvail);
      } else {
        setMaxAvailableDays(null);
      }
    },
    [availabilityStays, setMaxAvailableDays],
  );

  const selectStartDate = useCallback(
    (startDate) => {
      if (!maxSelectableDays) {
        onChangeDates({ startDate, endDate });
        return;
      }

      const startDateAfterEndDate = startDate >= endDate;
      const rangeAboveMaxRange = differenceInDays(endDate, startDate) >= maxSelectableDays;

      if (startDateAfterEndDate || rangeAboveMaxRange) {
        resetWithStartDate(startDate);
      } else {
        onChangeDates({ startDate, endDate });
      }
    },
    [endDate, maxSelectableDays, onChangeDates, resetWithStartDate],
  );

  const selectEndDate = useCallback(
    (endDate) => {
      if (endDate <= startDate) {
        setMaxAvailability(endDate);
        resetWithStartDate(endDate);
      } else {
        onChangeDates({ startDate, endDate });
      }
    },
    [startDate, onChangeDates, resetWithStartDate, setMaxAvailability],
  );

  const isDateHighlighted = useCallback(
    (date) => {
      if (isSameDay(date, startDate) || isSameDay(date, endDate)) return true;

      return isDateInRange({
        startDate,
        endDate,
        isSettingStartDate: isSettingStartDate(),
        hoveredDate,
        date,
      });
    },
    [startDate, endDate, hoveredDate, isSettingStartDate],
  );

  const isDateOutsideMaxSelectableDays = useCallback(
    (date) => {
      if (startDate == null || !maxSelectableDays) return false;

      // compare in MS as this is fast to evaluate and needs to run multiple times per render
      const time = date.getTime();
      const maxSelectableTime = startDate.getTime() + maxSelectableDays * MS_IN_A_DAY;
      const maxAvailableTime = startDate.getTime() + maxAvailableDays * MS_IN_A_DAY;
      const exceedsMaxAvailableTime = maxAvailableDays ? time >= maxAvailableTime : false;
      return time >= maxSelectableTime || exceedsMaxAvailableTime;
    },
    [startDate, maxSelectableDays, maxAvailableDays],
  );

  const isDateOutsideDateBoundaries = useCallback(
    (date) => {
      const { start, end } = boundaries;
      if (!start || !end) return false;
      return date.getTime() < start.getTime() || date.getTime() > end.getTime();
    },
    [boundaries],
  );

  const getCustomDateProps = useCallback(
    (day) => {
      const { date } = day;

      const time = dateFnsFormat(date, SEARCH_DATE_FORMAT);
      const availabilityIndex = availabilityStays.findIndex((stay) => stay.date === time);
      const { available = true } = availabilityStays[availabilityIndex] || {};
      const startTime = startDate ? dateFnsFormat(startDate, SEARCH_DATE_FORMAT) : null;
      const startAvail = findIndex(availabilityStays, ['date', startTime]);
      const isStartDate = startDate ? time === dateFnsFormat(startDate, SEARCH_DATE_FORMAT) : false;
      const firstUnavail = findIndex(availabilityStays, ['available', false], startAvail);
      const isDateAvailableForCheckout = availabilityIndex > 0 && firstUnavail > 0 && availabilityIndex === firstUnavail ? true : false;
      const minStay = isExclusive
        ? Math.min(...exclusiveOfferRoomTypes.map((r) => r.minNumberOfNights))
        : get(availabilityStays[startAvail], 'minStay') || 0;

      const customDateProps = {
        selected: day.selected,
        selectable: day.selectable,
        highlighted: isDateHighlighted(date),
        time,
        isMinStay: true,
        endDate: endDate,
        isHoveredDate: hoveredDate?.getTime() === day.date.getTime(),
        startDate: {
          isStartDate: isStartDate,
          startDateMinStay: minStay,
        },
      };

      if (
        (isSettingEndDate() && isDateOutsideMaxSelectableDays(date)) ||
        isDateOutsideDateBoundaries(date) ||
        (!available && !isDateAvailableForCheckout)
      ) {
        customDateProps.selectable = false;
        customDateProps.disabled = true;
      }

      if (isSettingEndDate() && startDate && (customDateProps.selectable || isDateAvailableForCheckout)) {
        if (availabilityIndex > 0 && firstUnavail > 0 && availabilityIndex === firstUnavail) {
          customDateProps.selectable = true;
          customDateProps.disabled = false;
        }

        const isPastMinDay = minStay === 0 ? 'true' : availabilityIndex > startAvail + minStay - 1;
        if (startDate > date) {
          customDateProps.selectable = true;
          customDateProps.disabled = false;
        } else if (!isPastMinDay && !isStartDate) {
          customDateProps.highlighted = true;
          customDateProps.isMinStay = false;
          customDateProps.disabled = true;
        }
      }
      return customDateProps;
    },
    [
      isExclusive,
      exclusiveOfferRoomTypes,
      availabilityStays,
      startDate,
      isDateHighlighted,
      endDate,
      isSettingEndDate,
      isDateOutsideMaxSelectableDays,
      isDateOutsideDateBoundaries,
      hoveredDate,
    ],
  );

  const onDateSelected = useCallback(
    ({ selectable, date: selectedDate }) => {
      if (!selectable || !isValid(selectedDate)) return;

      if (!startDate) {
        setMaxAvailability(selectedDate);
        selectStartDate(selectedDate);
        emitInteractionEvent({ type: 'Availability Calendar', value: `Check-in ${formatDate(selectedDate, SEARCH_DATE_FORMAT)}` });
      } else if (startDate && !endDate) {
        setMaxAvailableDays(null);
        selectEndDate(selectedDate);
        emitInteractionEvent({ type: 'Availability Calendar', value: `Check-out ${formatDate(selectedDate, SEARCH_DATE_FORMAT)}` });
      } else {
        setMaxAvailability(selectedDate);
        resetWithStartDate(selectedDate);
      }

      setHoveredDate(null);
    },
    [startDate, endDate, resetWithStartDate, selectEndDate, selectStartDate, setMaxAvailability, emitInteractionEvent],
  );

  const dayzedData = useDayzed({
    selected: selectedDates,
    date: initialDisplayDate,
    offset: isMobile ? 0 : offset,
    monthsToDisplay,
    firstDayOfWeek,
    minDate,
    onDateSelected,
    onOffsetChanged,
  });

  const { calendars, getBackProps, getForwardProps, getDateProps } = dayzedData;

  const backProps = getBackProps({ calendars });
  const forwardProps = getForwardProps({ calendars });

  const onChangeMonth = useCallback(
    (newDate) => {
      // Check if date is within the first and last valid date range
      if (isSettingEndDate() && isDateOutsideMaxSelectableDays(newDate)) {
        return;
      }

      const calendarYearOffsetInMonths = (calendars[0].year - new Date().getFullYear()) * 12;
      const calendarMonth = calendars[0].month + calendarYearOffsetInMonths;

      const yearOffsetInMonths = (newDate.getFullYear() - new Date().getFullYear()) * 12;
      const monthOffset = newDate.getMonth() + yearOffsetInMonths;
      // changing months in dayzed -needs- an event on which preventDefault() has not been invoked, so create a fake event here
      const event = new Event('dateChanged');
      focusDate.current = newDate;
      if (monthOffset > calendarMonth) {
        forwardProps.onClick(event);
      } else if (monthOffset < calendarMonth) {
        backProps.onClick(event);
      } else {
        const currentDateElement = calendarRef.current.querySelector(`[data-time='${dateFnsFormat(newDate, SEARCH_DATE_FORMAT)}'`);
        if (currentDateElement) {
          currentDateElement.focus();
        }
      }
    },
    [backProps, forwardProps, isDateOutsideMaxSelectableDays, isSettingEndDate, calendars],
  );

  if (!calendars.length) return null;

  return (
    <Box onMouseLeave={onMouseLeaveOfCalendar} position="relative">
      <Box>
        <CalendarNav backProps={backProps} forwardProps={forwardProps} />
        {/* <Box px={4} textAlign="center">
          <CalendarKey
            display={['flex', 'none']}
            marginTop={0}
            month={new Date().getMonth()}
            year={new Date().getFullYear()}
            weekdayNames={weekdayNames}
          />
        </Box> */}
      </Box>

      <Flex flexWrap="nowrap" ref={calendarRef} flexDirection={['column', 'row']} pt={['.5rem', 0]} style={{ gap: '2.5rem' }}>
        {calendars.map((calendar, i) => {
          return (
            <CalendarMonth
              {...(i === offset && { ref: selectedMonth })}
              key={`${calendar.month}${calendar.year}`}
              monthsToDisplay={monthsToDisplay}
              monthName={monthNames[calendar.month]}
              month={calendar.month}
              year={calendar.year}
              weekdayNames={weekdayNames}
              weeks={calendar.weeks}
              getDateProps={getDateProps}
              onMouseEnterOfDay={onMouseEnterOfDay}
              getCustomDateProps={getCustomDateProps}
              onChangeMonth={onChangeMonth}
              onEnteredView={onMonthScrolled}
              isLimited={isLimited}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

Calendar.defaultProps = {
  monthsToDisplay: 2,
  firstDayOfWeek: 1,
  minDate: subDays(new Date(), 1),
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  weekdayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  startDate: null,
  endDate: null,
  initialDisplayDate: new Date(),
  maxSelectableDays: 29,
  focusedInput: FOCUS.NONE,
  onChangeDates: () => {},
  boundaries: {},
  onMonthScrolled: () => {},
  isLimited: false,
};

Calendar.propTypes = {
  monthsToDisplay: PropTypes.number,
  firstDayOfWeek: PropTypes.number,
  minDate: PropTypes.instanceOf(Date),
  monthNames: PropTypes.arrayOf(PropTypes.string),
  weekdayNames: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  initialDisplayDate: PropTypes.instanceOf(Date),
  maxSelectableDays: PropTypes.number,
  focusedInput: PropTypes.oneOf(Object.values(FOCUS)),
  onChangeDates: PropTypes.func,
  boundaries: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
  onMonthScrolled: PropTypes.func,
  isLimited: PropTypes.bool,
};

export default Calendar;
