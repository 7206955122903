import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import { NakedButton, Box, Flex } from '@qga/roo-ui/components';

export const MinStayMessageWrapper = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  width: max-content;
  padding: ${themeGet('space.2')};
  box-shadow: ${themeGet('shadows.tooltip')};
  margin-bottom: ${themeGet('space.3')};

  ${mediaQuery.minWidth.sm} {
    z-index: ${themeGet('zIndices.fullscreenGallery')};
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -25px;
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -30px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const DayWrapper = styled(Box)`
  // flex: 1 1 auto;
  width: calc(100% / 7);
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${mediaQuery.minWidth.sm} {
    height: 2.875rem;
    &:after {
      padding-bottom: 0;
      padding-left: 100%;
      height: 100%;
    }
  }

  ${mediaQuery.minWidth.sm} {
    &:after {
      padding-bottom: 100%;
      padding-left: 0;
      height: unset;
    }
  }
`;
// ${mediaQuery.minWidth.sm} {
//   display: flex;
//   justify-content: center;

//   z-index: ${themeGet('zIndices.fullscreenGallery')};

//   ${(props) =>
//     props.selected &&
//     css`
//       &:after {
//         background: ${props?.isStartDate
//           ? 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(222 222 222) 50%, rgb(222 222 222) 100%)'
//           : 'linear-gradient(90deg, rgb(222 222 222) 0%, rgb(222 222 222) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)'};
//       }
//     `};
// }

// ${(props) =>
//   props.highlighted &&
//   !props.selected &&
//   css`
//     background: ${themeGet('colors.greys.alto')(props)};
//     &:not(:hover) {
//     }
//     &:has(> button:hover) {
//       background: linear-gradient(90deg, rgb(222 222 222) 0%, rgb(222 222 222) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
//     }
//   `}

DayWrapper.defaultProps = {
  ...Box.defaultProps,
};

DayWrapper.displayName = 'DayWrapper';

export const Button = styled(NakedButton)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: ${themeGet('colors.greys.charcoal')};
  padding: 0;

  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.2;
  font-weight: ${themeGet('fontWeights.bold')};

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.greys.dusty')} !important;
    text-decoration: line-through;
  }
  display: flex;
  justify-content: center;

  [data-key] {
    aspect-ratio: 1;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9999rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${props?.isStartDate
        ? 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(222 222 222) 50%, rgb(222 222 222) 100%)'
        : 'linear-gradient(90deg, rgb(222 222 222) 0%, rgb(222 222 222) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)'};

      [data-key] {
        background: ${themeGet('colors.greys.charcoal')(props)};
        color: #fff;
      }
    `};

  ${(props) =>
    !props.selected &&
    props.highlighted &&
    !props.isHoveredDate &&
    css`
      background: ${themeGet('colors.greys.alto')(props)};
    `};

  ${(props) =>
    !props.selected &&
    props.highlighted &&
    props.isHoveredDate &&
    css`
      background: linear-gradient(90deg, rgb(222 222 222) 0%, rgb(222 222 222) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
      [data-key] {
        background: ${themeGet('colors.greys.alto')(props)};
        border-color: ${themeGet('colors.greys.charcoal')(props)};
      }
    `};

  ${(props) =>
    !props.highlighted &&
    props.selectable &&
    css`
      [data-key]:hover,
      [data-key]:focus {
        border-color: ${themeGet('colors.greys.charcoal')(props)};
      }
    `};

  ${(props) =>
    !props.selectable &&
    !props.disabled &&
    css`
      cursor: not-allowed;
      color: ${themeGet('colors.greys.dusty')} !important;
      text-decoration: line-through;
    `};

  ${mediaQuery.minWidth.sm} {
    height: 2.875rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

Button.displayName = 'Button';
Button.defaultProps = {
  ...NakedButton.defaultProps,
  disabled: PropTypes.bool,
};
