import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { Wrapper } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import { themeGet } from 'styled-system';
import { format } from 'lib/date';
import { FOCUS } from '../constants';
import InputWithAddon from 'components/InputWithAddon';

const DEFAULT_DATE_FORMAT = 'd MMM';

const formatDate = (date, dateFormat) => {
  if (date) {
    return format(date, dateFormat);
  } else {
    return '';
  }
};

const DateInput = styled(InputWithAddon)`
  &:focus {
    border-color: ${themeGet('colors.ui.linkFocus')};
  }

  padding-left: 44px !important;
  padding-right: 12px !important;

  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.5;

  &::placeholder {
    color: ${themeGet('colors.greys.steel')};
  }
`;

const PhoneDateDisplay = ({ range, dateFormat, labelOptions, handleFocusedInput, error, isOptional, ...rest }) => {
  const handleFocus = useCallback(() => handleFocusedInput(FOCUS.START_DATE), [handleFocusedInput]);
  const onKeyUp = useCallback(
    ({ keyCode }) => {
      if (keyCode === 13) handleFocus();
    },
    [handleFocus],
  );

  const { startDate, endDate } = range;
  const dateInputValue = useMemo(
    () => (startDate || endDate ? `${formatDate(startDate, dateFormat)} - ${formatDate(endDate, dateFormat)}` : ''),
    [startDate, endDate, dateFormat],
  );

  return (
    <Wrapper {...rest}>
      <Label data-testid="phone-date-display">
        <LabelText {...labelOptions} hidden>
          Check in and Check out dates
        </LabelText>
        <DateInput
          left={2}
          icon="dateRange"
          borderRadius="default"
          border={2}
          value={dateInputValue}
          placeholder="Select dates"
          onClick={handleFocus}
          onFocus={handleFocus}
          onKeyUp={onKeyUp}
          error={error}
          suffix={isOptional ? 'Optional' : ''}
          readonly
          inputMode="none"
        />
      </Label>
    </Wrapper>
  );
};

PhoneDateDisplay.propTypes = {
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  dateFormat: PropTypes.string,
  handleFocusedInput: PropTypes.func,
  labelOptions: PropTypes.object,
  error: PropTypes.bool,
  isOptional: PropTypes.bool,
};

PhoneDateDisplay.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  range: {},
  labelOptions: {},
  handleFocusedInput: noop,
  error: false,
  isOptional: false,
};

export default PhoneDateDisplay;
