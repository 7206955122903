import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box, Flex, Label, Text, Icon, NakedButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import TreatmentToggle from 'components/TreatmentToggle';

const OccupantPickerButton = styled(NakedButton)`
  touch-action: manipulation;
`;

const UpdatedOccupantPickerButton = styled(OccupantPickerButton)`
  color: ${themeGet('colors.brand.secondary')};

  &:hover {
    color: ${themeGet('colors.ui.linkHover')};
  }

  &:disabled {
    color: ${themeGet('colors.greys.grey')};
  }
`;

const Ages = styled.span`
  font-weight: ${themeGet('fontWeights.normal')};
  margin-left: 7px;
`;

const UpdatedAges = styled(Ages)`
  color: ${themeGet('colors.greys.iron')};
  font-size: ${themeGet('fontSizes.xs')};
  line-height: 20px;
  margin-left: 0;
`;

const Occupant = ({ label, ages, count, increment, decrement, min, max, ...rest }) => (
  <>
    <TreatmentToggle split="jh_header_nav" treatment="off">
      <Box p={4} {...rest}>
        <Label>
          {label}
          {ages && <Ages data-testid="occupant-ages">({ages})</Ages>}
        </Label>
        <Flex alignItems="center" bg="white">
          {count > min ? (
            <OccupantPickerButton flex="1 1 auto" p={3} onClick={decrement} data-testid="decrement">
              <Icon name="remove" />
            </OccupantPickerButton>
          ) : (
            <Box textAlign="center" p={3}>
              <Icon name="remove" color="greys.alto" />
            </Box>
          )}
          <Flex flex="3" justifyContent="center" data-testid="occupant-count">
            {count}
          </Flex>
          {count < max ? (
            <OccupantPickerButton flex="1 1 auto" p={3} onClick={increment} data-testid="increment">
              <Icon name="add" />
            </OccupantPickerButton>
          ) : (
            <Box textAlign="center" p={3}>
              <Icon name="add" color="greys.alto" />
            </Box>
          )}
        </Flex>
      </Box>
    </TreatmentToggle>
    <TreatmentToggle split="jh_header_nav" treatment="on">
      <Flex alignItems="center" justifyContent="space-between" {...rest}>
        <Flex flexDirection="column" justifyContent="center" minHeight="3rem" style={{ gap: '.25rem' }}>
          <Text as="div" fontSize="sm" lineHeight={1.5} fontWeight="bold" color="greys.charcoal" data-testid="occupant-label">
            {label}
          </Text>
          {ages && (
            <UpdatedAges as="div" data-testid="occupant-ages">
              {ages}
            </UpdatedAges>
          )}
        </Flex>
        <Flex alignItems="center">
          <UpdatedOccupantPickerButton onClick={decrement} data-testid="decrement" disabled={count <= min}>
            <Icon name="removeCircleOutline" size="2.5rem" />
          </UpdatedOccupantPickerButton>
          <Text
            as="div"
            fontSize="xl"
            lineHeight={1.25}
            fontWeight="bold"
            textAlign="center"
            data-testid="occupant-count"
            style={{ width: '3.5rem' }}
          >
            {count}
          </Text>
          <UpdatedOccupantPickerButton onClick={increment} data-testid="increment" disabled={count >= max}>
            <Icon name="addCircleOutline" size="2.5rem" />
          </UpdatedOccupantPickerButton>
        </Flex>
      </Flex>
    </TreatmentToggle>
  </>
);

Occupant.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  ages: PropTypes.string,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

Occupant.defaultProps = {
  ages: undefined,
};

export default Occupant;
