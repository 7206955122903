import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import styled from '@emotion/styled';
import { Text } from '@qga/roo-ui/components';
import { useTooltip } from 'lib/hooks';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { DayWrapper, Button, MinStayMessageWrapper } from './CalendarDay.style';

export const CalendarDay = ({ children, selected, time, isMinStay, endDate, startDate, isLimited, isHoveredDate, ...rest }) => {
  const { isStartDate, startDateMinStay } = startDate;
  const { triggerProps, tooltipProps, arrowProps, isOpen } = useTooltip('min-stay', {}, 'top');
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobile = isLessThanBreakpoint(0);

  const minStayNightsText = `${pluralize('night', startDateMinStay, true)} minimum`;
  const showMinStayMessage = !isMinStay || isStartDate;

  return (
    <DayWrapper selected={selected} date-testid="day-wrapper">
      <Button selected={selected} isStartDate={isStartDate} isHoveredDate={isHoveredDate} data-time={time} {...rest}>
        <div {...triggerProps} data-key>
          {children}
        </div>
        {!isMobile && isOpen && showMinStayMessage && !isLimited && (
          <MinStayMessageWrapper {...tooltipProps}>
            <Text>{minStayNightsText}</Text>
            <div {...arrowProps} />
          </MinStayMessageWrapper>
        )}
        {isMobile && isStartDate && !endDate && !isLimited && (
          <MinStayMessageWrapper {...tooltipProps}>
            <Text>{minStayNightsText}</Text>
            <div {...arrowProps} />
          </MinStayMessageWrapper>
        )}
      </Button>
    </DayWrapper>
  );
};

CalendarDay.defaultProps = {
  selectable: true,
  disabled: false,
  selected: false,
  highlighted: false,
  isMinStay: true,
  startDate: { isStartDate: false, startDateMinStay: null },
  endDate: null,
};

CalendarDay.propTypes = {
  children: PropTypes.node.isRequired,
  time: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  isMinStay: PropTypes.bool,
  startDate: PropTypes.shape({ isStartDate: PropTypes.bool, startDateMinStay: PropTypes.number }),
  endDate: PropTypes.instanceOf(Date),
  isLimited: PropTypes.bool,
  isHoveredDate: PropTypes.bool,
};

export const CalendarEmptyDay = styled(DayWrapper.withComponent('div'))`
  border-color: transparent;
`;

CalendarEmptyDay.displayName = 'CalendarEmptyDay';
