import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mediaQuery } from 'lib/styledSystem';
import { themeGet } from 'styled-system';
import { Flex, Box, Text } from '@qga/roo-ui/components';

const CalendarKeyWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  z-index: ${themeGet('zIndices.mobileModal')};
  background-color: ${themeGet('colors.white')};

  ${mediaQuery.minWidth.md} {
    align-items: center;
    position: relative;
    background-color: none;
    width: auto;
  }
`;

CalendarKeyWrapper.displayName = 'CalendarKeyWrapper';

const CalendarKey = ({ weekdayNames, month, year, ...rest }) => {
  return (
    <CalendarKeyWrapper {...rest}>
      {weekdayNames.map((weekday, index) => (
        <Box width="calc(100% / 7)" key={`${month}${year}${weekday}${index}`} data-testid="weekday-box">
          <Text>{weekday}</Text>
        </Box>
      ))}
    </CalendarKeyWrapper>
  );
};

CalendarKey.propTypes = {
  weekdayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export default CalendarKey;
