import React, { ReactNode } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { NakedButtonProps, NakedButton } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

interface SelectButtonProps {
  active: boolean;
}

export const StyledButton = styled(NakedButton, { shouldForwardProp: isPropValid })<SelectButtonProps>`
  cursor: pointer;
  display: flex;
  min-height: 48px;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 26px;
  border-color: ${(props) => (props.active ? themeGet('colors.brand.primary') : themeGet('colors.greys.alto'))};
  background-color: ${themeGet('colors.white')};
  color: ${(props) => (props.active ? themeGet('colors.brand.primary') : themeGet('colors.greys.steel'))};
  font-weight: 400;
  font-size: ${themeGet('fontSizes.base')};
  width: 100%;

  &:hover {
    margin: 0;
    border-color: ${themeGet('colors.brand.primary')};
    color: ${themeGet('colors.brand.primary')};
  }

  white-space: nowrap;
`;

interface Props extends NakedButtonProps {
  active?: boolean;
}

const Filter = React.forwardRef<ReactNode, Props>(({ active = false, height = '48px', children, ...rest }: Props, ref) => {
  return (
    <StyledButton data-testid="room-filter-button" px={5} height={height} active={active} ref={ref} {...rest}>
      {children}
    </StyledButton>
  );
});

Filter.displayName = 'Filter';

export default Filter;
