import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import pluralize from 'pluralize';
import { DISPLAY_DATE_FORMAT } from 'config';
import { format as formatDate, differenceInDays } from 'date-fns';

export const StyledText = styled(Text)`
  cursor: pointer;
  display: flex;
  min-height: 48px;
  align-items: center;
`;

interface Props {
  checkIn: Date;
  checkOut: Date;
}

const checkInMessage = 'Select check-in date';
const checkOutMessage = 'Select check-out date';
const defaultDescriptionMessage = 'Select travel dates for availability';

const StaySummary = ({ checkIn, checkOut }: Props) => {
  const isDefaultState = !checkIn;
  const numberOfNights = checkIn && checkOut ? differenceInDays(checkOut, checkIn) : null;
  const formattedCheckIn = checkIn ? formatDate(checkIn, DISPLAY_DATE_FORMAT) : null;
  const formattedCheckOut = checkOut ? formatDate(checkOut, DISPLAY_DATE_FORMAT) : null;
  const selectDateMessaging = checkOut ? `${pluralize('night', numberOfNights, true)} stay` : checkOutMessage;

  return (
    <Box>
      <Text as="div" fontSize="md" lineHeight={1.2} fontWeight="bold">
        {isDefaultState ? checkInMessage : selectDateMessaging}
      </Text>
      <Text as="div" fontSize="sm" lineHeight={1.5} color="greys.iron" mt={1}>
        {isDefaultState ? (
          defaultDescriptionMessage
        ) : (
          <>
            <Text>{formattedCheckIn}</Text>
            <Text>{' - '}</Text>
            <Text>{formattedCheckOut}</Text>
          </>
        )}
      </Text>
    </Box>
  );
};

export default StaySummary;
