import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { useDataLayer } from 'hooks/useDataLayer';
import { Box, Text, Flex, Icon, NakedButton, Link } from '@qga/roo-ui/components';
import { MULTI_ROOM_BOOKING_PROMPT_MESSAGE, REQUEST_CALLBACK_PHONE_NUMBER } from 'config';
import { setRequestCallbackModalOpen } from 'store/property/propertyActions';
import { useDispatch } from 'react-redux';
import TreatmentToggle from 'components/TreatmentToggle';

const StyledMultiRoomMessage = styled(Box)`
  font-size: ${themeGet('fontSizes.sm')};
  line-height: ${themeGet('lineHeights.loose')};
  letter-spacing: 0px;
  text-align: left;
`;

const StyledLinkButton = styled(NakedButton)`
  display: contents;

  cursor: pointer;
  text-decoration: none;

  color: ${themeGet('colors.ui.link')};
  font-weight: 600;

  &:hover {
    color: ${themeGet('colors.ui.linkHover')};
  }

  &:focus {
    outline: ${themeGet('borders.2')} ${themeGet('colors.brand.secondary')};
  }
`;

const MultiRoomMessage = () => {
  const dispatch = useDispatch();

  const { emitInteractionEvent } = useDataLayer();

  const handleRequestCallbackClicked = () => {
    dispatch(setRequestCallbackModalOpen(true));
    emitInteractionEvent({
      type: 'Request Callback Modal',
      value: 'Request Form Opened',
      customAttributes: {
        user_event_value: 'MultiRoom Message',
      },
    });
  };

  const handleCallCenterClicked = () => {
    emitInteractionEvent({
      type: 'Request Callback Modal',
      value: 'Call Centre Phone Number Selected',
      customAttributes: {
        user_event_value: 'MultiRoom Message',
      },
    });
  };

  return (
    <StyledMultiRoomMessage>
      <TreatmentToggle split="jh_header_nav" treatment="off">
        <Flex flexDirection="row" justifyContent={['flex-start', 'center']} px={2}>
          <Box data-testid="multi-room-info-icon" minHeight={48}>
            <Icon name="errorOutline" color="greys.charcoal" />
          </Box>
          <Box ml={[2, 2]} textAlign={['start']}>
            <Text display="inline" data-testid="multi-room-prompt-message">
              {MULTI_ROOM_BOOKING_PROMPT_MESSAGE}
            </Text>
            <Link
              href={`tel:${REQUEST_CALLBACK_PHONE_NUMBER}`}
              aria-label={`Contact Goulburn call center on ${REQUEST_CALLBACK_PHONE_NUMBER}`}
              data-testid="request-callback-number"
              fontWeight="bold"
              color="greys.charcoal"
              onClick={handleCallCenterClicked}
            >
              {REQUEST_CALLBACK_PHONE_NUMBER}
            </Link>
            {` `}or{` `}
            <NakedButton onClick={handleRequestCallbackClicked} data-testid="request-callback-cta">
              <Text fontWeight="bold" textDecoration="underline">
                request a call back
              </Text>
            </NakedButton>
          </Box>
        </Flex>
      </TreatmentToggle>
      <TreatmentToggle split="jh_header_nav" treatment="on">
        <Flex flexDirection="row" justifyContent={['flex-start', 'center']} pt=".375rem" style={{ gap: '.375rem' }}>
          <Box data-testid="multi-room-info-icon">
            <Icon name="error" color="greys.steel" />
          </Box>
          <Text a="div" fontSize="sm" lineHeight={1.75} textAlign={'start'} color="greys.iron" data-testid="multi-room-prompt-message">
            {MULTI_ROOM_BOOKING_PROMPT_MESSAGE}
            <Link
              href={`tel:${REQUEST_CALLBACK_PHONE_NUMBER}`}
              aria-label={`Contact Goulburn call center on ${REQUEST_CALLBACK_PHONE_NUMBER}`}
              data-testid="request-callback-number"
              fontWeight="bold"
              onClick={handleCallCenterClicked}
            >
              {REQUEST_CALLBACK_PHONE_NUMBER}
            </Link>
            {` `}or{` `}
            <StyledLinkButton onClick={handleRequestCallbackClicked} data-testid="request-callback-cta">
              request a call back
            </StyledLinkButton>
          </Text>
        </Flex>
      </TreatmentToggle>
    </StyledMultiRoomMessage>
  );
};

export default MultiRoomMessage;
