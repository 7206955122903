import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsInViewport from './useIsInViewport';
import { getTreatments } from 'store/split/splitSelectors';
import { viewPromotion } from 'store/campaign/campaignActions';

interface UseViewPromotionEventProps {
  ref?: React.RefObject<HTMLElement>;
  promotion: {
    slot: string;
    name?: string;
    assetName?: string;
  };
}

export default function useViewPromotionEvent({ ref, promotion }: UseViewPromotionEventProps) {
  const dispatch = useDispatch();
  const isInViewport = useIsInViewport(ref);
  const usingViewport = ref?.current;
  const treatments = useSelector(getTreatments);
  const isGA4 = treatments?.ga4_schema_jq?.treatment === 'on';
  const [fired, setFired] = useState(false);

  useEffect(() => {
    if (!promotion.name || !isInViewport || fired) return;

    dispatch(
      viewPromotion({
        isGA4,
        promotion,
      }),
    );

    setFired(true);
  }, [dispatch, fired, isGA4, isInViewport, promotion, usingViewport]);
}
