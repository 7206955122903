import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { Flex, Box } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import { themeGet } from 'styled-system';
import { format } from 'lib/date';
import { FOCUS } from '../constants';
import InputWithAddon from 'components/InputWithAddon';

const DEFAULT_DATE_FORMAT = 'EEE d MMM, yyyy';

const InputDivider = styled(Box)`
  position: absolute;
  right: 0;
  bottom: ${(props) => (props.isHighlighted ? 0 : themeGet('space.2')(props))};
  top: ${(props) => (props.isHighlighted ? 0 : themeGet('space.2')(props))};
  border-right-width: ${(props) => (props.isHighlighted ? '2px' : '1px')};
  border-right-color: ${(props) =>
    props.isHighlighted ? themeGet('colors.brand.secondary')(props) : themeGet('colors.greys.alto')(props)};
  border-right-style: solid;
  width: 0px;
`;

InputDivider.defaultProps = {
  ...Box.defaultProps,
};

const CheckInDateInput = styled(InputWithAddon)`
  padding: 12px;
  padding-left: 44px;

  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.5;

  &::placeholder {
    color: ${themeGet('colors.greys.steel')};
  }
`;

const DateDisplay = ({
  range,
  dateFormat,
  focusedInput,
  handleFocusedInput,
  labelOptions,
  error,
  backgroundColor,
  isOptional,
  ...rest
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (focusedInput === FOCUS.DATE) inputRef.current.focus();
  }, [focusedInput]);

  const handleFocusDate = useCallback(() => handleFocusedInput(FOCUS.DATE), [handleFocusedInput]);

  const { startDate, endDate } = range;
  const dateFocused = useMemo(() => focusedInput === FOCUS.DATE, [focusedInput]);

  const startDateMessage = startDate ? format(startDate, dateFormat) : '';
  const endDateMessage = endDate ? format(endDate, dateFormat) : '';

  return (
    <Flex {...rest} backgroundColor={backgroundColor}>
      <Box flex="1 1 auto">
        <Label>
          <LabelText {...labelOptions}>When?</LabelText>
          <Box position="relative">
            <CheckInDateInput
              icon="calendar"
              ref={inputRef}
              placeholder="Select dates"
              value={startDate ? `${startDateMessage} - ${endDateMessage}` : ''}
              onFocus={handleFocusDate}
              isHighlighted={dateFocused}
              readOnly
              data-testid="select-date-input"
              borderRadius="default"
              border={2}
              cursor="pointer"
              error={error}
              suffix={isOptional ? 'Optional' : ''}
            />
          </Box>
        </Label>
      </Box>
    </Flex>
  );
};

DateDisplay.propTypes = {
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  dateFormat: PropTypes.string,
  handleFocusedInput: PropTypes.func,
  focusedInput: PropTypes.string,
  labelOptions: PropTypes.object,
  error: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isOptional: PropTypes.bool,
};

DateDisplay.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  focusedInput: FOCUS.NONE,
  handleFocusedInput: noop,
  range: {},
  labelOptions: {},
  error: false,
  backgroundColor: 'white',
  isOptional: false,
};

export default DateDisplay;
