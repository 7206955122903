import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import { NakedButton, Flex } from '@qga/roo-ui/components';

export const StaySummaryWrapper = styled(Flex)`
  position: fixed;
  flex-direction: column;
  padding-bottom: ${themeGet('space.4')};
  justify-content: space-between;
  width: 100%;
  height: 100px;
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};
  background-color: white;
  ${mediaQuery.minWidth.sm} {
    margin-top: ${themeGet('space.4')};
    position: relative;
    flex-direction: row;
    height: auto;
  }
`;

export const FilterWrapper = styled(Flex)`
  padding-right: ${themeGet('space.8')};
  justify-content: flex-start;
  background-color: white;
  padding: ${themeGet('space.3')} 0;
  ${mediaQuery.minWidth.sm} {
    padding: 0;
    margin-top: 0;
    height: auto;
    width: 50%;
    position: relative;
    justify-content: flex-start;
  }
`;

export const Footer = styled(Flex)`
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${themeGet('colors.greys.alto')};
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  background: ${themeGet('colors.white')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: ${themeGet('space.5')} ${themeGet('space.8')};
    border-top: 1px solid ${themeGet('colors.greys.alto')};
  }
`;

export const StickyHeader = styled(Flex)`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  padding: ${themeGet('space.4')};
  z-index: ${(props) => (props.showMenu ? themeGet('zIndices.modal')(props) : themeGet('zIndices.mobileModal')(props))};

  ${mediaQuery.minWidth.sm} {
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: ${themeGet('space.6')} ${themeGet('space.8')};
    border-radius: 10px 10px 0 0;
    justify-content: space-between;
  }

  ${mediaQuery.mobileOnlyEscapeHatch} {
    position: sticky;
    top: 0;
  }

  ${(props) =>
    props.isMobileApp &&
    css`
      margin: 30px 5% 0;
    `};
`;

export const StickyFooter = styled(Flex)`
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${themeGet('colors.greys.alto')};
  background: ${themeGet('colors.white')};
  z-index: ${themeGet('zIndices.modalContent')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: ${themeGet('space.3')} ${themeGet('space.8')};
    border-radius: 0px 0px 10px 10px;
    border-top: 1px solid ${themeGet('colors.greys.alto')};
    z-index: ${themeGet('zIndices.modalContent')};
  }
`;

export const CancelButton = styled(NakedButton)`
  font-size: ${themeGet('fontSizes.sm')};
  line-height: ${themeGet('lineHeights.loose')};
  font-weight: ${themeGet('fontWeights.bold')};

  color: ${themeGet('colors.brand.secondary')};
  padding: ${themeGet('space.2')} 0;
`;

export const Actions = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${themeGet('colors.greys.alto')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    border: none;
  }

  ${mediaQuery.mobileOnlyEscapeHatch} {
    padding: ${themeGet('space.6')} ${themeGet('space.8')};
  }

  ${(props) =>
    props.isMobileApp &&
    css`
      position: fixed;
      bottom: 5%;
      left: 5%;
      right: 5%;
      background-color: white;
    `};
`;

export const ConfirmButton = styled(NakedButton)`
  border-radius: 6px;
  padding: ${themeGet('space.2')} ${themeGet('space.8')};

  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.brand.secondary')};
  font-size: ${themeGet('fontSizes.sm')};
  line-height: ${themeGet('lineHeights.loose')};
  font-weight: ${themeGet('fontWeights.bold')};
`;
