import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { subDays, format as dateFnsFormat, addDays } from 'date-fns';
import { useDataLayer } from 'hooks/useDataLayer';
import { Box, Link } from '@qga/roo-ui/components';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import includes from 'lodash/includes';
import { useBreakpoints } from 'hooks/useBreakpoints';
import ResponsiveModal from 'components/ResponsiveModal';
import Calendar from './Calendar';
import DateDisplay from './DateDisplay';
import PhoneDateDisplay from './PhoneDateDisplay';
import { FOCUS } from './constants';
import Dropdown from 'components/Dropdown';
import { CancelButton, ConfirmButton, FilterWrapper, StickyFooter, Actions, StickyHeader } from './DatePicker.style';
import { fetchCalendar, clearCalendar } from 'store/calendar/calendarActions';
import { getFirstCalendarDay, getLastCalendarDay } from 'lib/localDate/utils';
import { getExclusiveOfferRoomTypes } from 'store/exclusiveOffer/exclusiveOfferSelectors';
import { getIsExclusive, getPropertyId } from 'store/property/propertySelectors';
import { rem } from 'polished';
import { SEARCH_DATE_FORMAT } from 'config';
import { getPageName, getQueryRoomTypeId } from 'store/router/routerSelectors';
import { useRouter } from 'next/router';
import Select from './Select';
import Filter from './Select/components/Filter';
import StaySummary from 'components/StaySummary';
import OfferTermsModal from './OfferTermsModal';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import debounce from 'lodash/debounce';
import { clearResults } from 'store/propertyAvailability/propertyAvailabilityActions';

const AvailabilityDatePicker = ({
  selectedDates,
  labelOptions,
  updateQuery,
  anchorX,
  viewThreshold,
  submitOnChange,
  minDate,
  initialDisplayDate,
  boundaries,
  error,
  maxSelectableDays,
  // clearSelectedDates,
  isLimited,
  backgroundColor,
  isOptional,
  extraMonthBreakpoint,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { emitInteractionEvent } = useDataLayer();
  const [nextDates, setNextDates] = useState(selectedDates);
  const [showMenu, setShowMenu] = useState(false);
  const [focusedInput, setFocusedInput] = useState(FOCUS.NONE);
  const [monthsToDisplay, setMonthsToDisplay] = useState([]);
  const { isLessThanBreakpoint } = useBreakpoints();
  const startDate = getFirstCalendarDay(selectedDates.startDate);
  const endDate = getLastCalendarDay(selectedDates.startDate);
  const propertyId = useSelector(getPropertyId);
  const isExclusive = useSelector(getIsExclusive);
  const payload = useMemo(
    () => ({ propertyId, startDate, endDate, luxeOnly: !!isExclusive }),
    [propertyId, startDate, endDate, isExclusive],
  );
  // eslint-disable-next-line no-unused-vars
  const roomTypeId = useSelector(getQueryRoomTypeId); // Pass this to the calendar availability fetcher
  const hash = (typeof window !== 'undefined' && window?.location?.hash) || '';
  const exclusiveOfferRoomTypes = useSelector(getExclusiveOfferRoomTypes);
  const mappedExclusiveOfferRoomTypes = exclusiveOfferRoomTypes.map(({ id, extranet }) => ({ value: id, text: extranet.name }));
  const isMobileApp = useSelector(getIsMobileApp);
  const isSearchPage = useSelector(getPageName) === 'search-list';

  const isMobile = isLessThanBreakpoint(0);

  const clearHash = useCallback(() => {
    router.replace(
      {
        hash: null,
        query: router.query,
        pathname: router.pathname,
      },
      null,
      { shallow: true, scroll: false },
    );
    setFocusedInput(FOCUS.NONE);
  }, [router]);

  useEffect(() => {
    if (hash === '#rooms') {
      setFocusedInput(FOCUS.START_DATE);
    }
  }, [hash, setFocusedInput]);

  useEffect(() => {
    setNextDates(selectedDates);
  }, [selectedDates]);

  const totalMonthsMobile = useMemo(() => {
    if (monthsToDisplay.length < 2) {
      return 2;
    } else {
      return monthsToDisplay.length + 1;
    }
  }, [monthsToDisplay]);

  const isDirty = useCallback(() => !isEqual(nextDates, selectedDates), [nextDates, selectedDates]);

  const isValid = useCallback(() => every(nextDates, (date) => date instanceof Date), [nextDates]);

  const onSelectedChange = (id) => {
    const roomType = exclusiveOfferRoomTypes.find((rt) => rt.id === id);
    const payload = { propertyId, offerId: roomType.offerId, startDate, endDate, luxeOnly: !!isExclusive };

    if (!isLimited) {
      dispatch(clearCalendar());
      dispatch(fetchCalendar(payload));
    }
    router.replace(
      {
        query: { ...router.query, roomTypeId: roomType.id },
      },
      null,
      { shallow: true },
    );

    setNextDates({ startDate: undefined, endDate: undefined });
  };

  const applyChange = useCallback(() => {
    updateQuery({
      checkIn: nextDates.startDate,
      checkOut: nextDates.endDate,
    });
    emitInteractionEvent({ type: 'Availability Calendar', value: 'Apply changes' });
  }, [updateQuery, nextDates, emitInteractionEvent]);

  const cancelChange = useCallback(() => {
    setFocusedInput(FOCUS.NONE);
    setNextDates(selectedDates);
  }, [setFocusedInput, setNextDates, selectedDates]);

  const nextInputFocus = useCallback(
    ({ startDate, endDate }) => {
      const noEndDateSet = endDate === null;
      const isUpdatingStartDate = startDate && !isEqual(startDate, nextDates.startDate);

      return noEndDateSet || isUpdatingStartDate ? FOCUS.END_DATE : FOCUS.NONE;
    },
    [nextDates],
  );

  const handleFocusedInput = useCallback(
    ({ openModal }) =>
      (nextFocusedInput) => {
        emitInteractionEvent({ type: 'Availability Calendar', value: 'Open Calendar' });
        const numMonths = isLessThanBreakpoint(0) ? totalMonthsMobile : 2;
        const newMonthsToDisplay = Array.from({ length: numMonths }, (_, index) => {
          const startDateObj = new Date(startDate);
          const currentMonthDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + index, 1);
          return dateFnsFormat(currentMonthDate, SEARCH_DATE_FORMAT);
        });
        setMonthsToDisplay(newMonthsToDisplay);
        setFocusedInput(nextFocusedInput);
        openModal();
        if (!isLimited) {
          dispatch(fetchCalendar(payload));
        }
      },

    [setFocusedInput, dispatch, payload, isLessThanBreakpoint, startDate, totalMonthsMobile, emitInteractionEvent, isLimited],
  );

  const handleApply = useCallback(
    (event) => {
      if (event && !submitOnChange) event.preventDefault();
      if (!isDirty()) {
        dispatch(clearCalendar());
        clearHash();
        return;
      }
      if (isValid()) {
        applyChange();
      }
      clearHash();
    },
    [isDirty, applyChange, isValid, submitOnChange, dispatch, clearHash],
  );

  const handleViewAllRoom = useCallback(() => {
    dispatch(clearCalendar());
    dispatch(fetchCalendar(payload));

    // eslint-disable-next-line no-unused-vars
    const { roomTypeId: _, ...rest } = router.query;
    router.replace(
      {
        query: rest,
      },
      null,
      { shallow: true },
    );

    emitInteractionEvent({ type: 'Availability Calendar', value: 'View all room button selected' });
  }, [router, dispatch, payload, emitInteractionEvent]);

  const handleCancel = useCallback(() => {
    cancelChange();
    dispatch(clearCalendar());
    // clearHash();
    // clearSelectedDates();
    emitInteractionEvent({ type: 'Availability Calendar', value: 'Close calendar' });
  }, [
    cancelChange,
    dispatch,
    //  clearHash,
    // clearSelectedDates,
    emitInteractionEvent,
  ]);

  const handleChangeDateRange = useCallback(
    (dates) => {
      const nextFocusedInput = nextInputFocus(dates);
      setNextDates(dates);
      setFocusedInput(nextFocusedInput);
    },
    [nextInputFocus],
  );

  const onMonthScrolled = debounce(({ startOfMonthString, endOfMonthString }) => {
    if (!includes(monthsToDisplay, startOfMonthString)) {
      setMonthsToDisplay([...monthsToDisplay, startOfMonthString]);

      const todayString = dateFnsFormat(new Date(), SEARCH_DATE_FORMAT);
      const isStartOfMonthBeforeToday = startOfMonthString.localeCompare(todayString) < 0;

      const monthPayload = {
        propertyId,
        startDate: isStartOfMonthBeforeToday ? todayString : startOfMonthString,
        endDate: dateFnsFormat(addDays(new Date(endOfMonthString), 1), SEARCH_DATE_FORMAT),
        luxeOnly: false,
      };
      if (!isLimited) {
        dispatch(fetchCalendar(monthPayload));
      }
    }
  }, 200);

  const onClearDates = useCallback(() => {
    setFocusedInput(FOCUS.NONE);
    if (!isSearchPage) {
      updateQuery({
        checkIn: undefined,
        checkOut: undefined,
      });
      dispatch(clearResults());
    }
    setNextDates({ startDate: undefined, endDate: undefined });
    emitInteractionEvent({ type: 'Availability Calendar', value: 'Clear Calendar' });
  }, [dispatch, emitInteractionEvent, isSearchPage, setNextDates, updateQuery]);

  const [showModal, setShowModal] = useState(false);

  const [tabletNumberOfCalendars, setTabletNumberOfCalendars] = useState(() => {
    return typeof window !== 'undefined' ? (window.innerWidth >= extraMonthBreakpoint ? 2 : 1) : 1;
  });

  const resizeHandler = React.useCallback(() => {
    if (window.innerWidth >= extraMonthBreakpoint && tabletNumberOfCalendars === 1) setTabletNumberOfCalendars(2);
    else if (window.innerWidth < extraMonthBreakpoint && tabletNumberOfCalendars === 2) setTabletNumberOfCalendars(1);
  }, [tabletNumberOfCalendars, setTabletNumberOfCalendars, extraMonthBreakpoint]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return (
    <ResponsiveModal
      title="Select dates"
      onSubmit={handleApply}
      onCancel={handleCancel}
      onBlur={handleApply}
      enableHeader={!showModal}
      isSubmitOnCancel={true}
      enableFooter={false}
      backgroundColor={['white', 'greys.porcelain']}
    >
      {({ isOpen, openModal, submitModal }) => (
        <>
          <Box position={['relative', 'relative', 'unset']}>
            <PhoneDateDisplay
              display={['block', 'none']}
              range={nextDates}
              dateFormat="EEE d MMM"
              handleFocusedInput={handleFocusedInput({ openModal })}
              labelOptions={labelOptions}
              error={error}
              isOptional={isOptional}
            />
            <DateDisplay
              display={['none', 'flex']}
              range={nextDates}
              handleFocusedInput={handleFocusedInput({ openModal })}
              focusedInput={focusedInput}
              labelOptions={labelOptions}
              error={error}
              backgroundColor={backgroundColor}
              isOptional={isOptional}
            />
            {isOpen && (
              <Dropdown
                anchorX={anchorX}
                viewThreshold={viewThreshold}
                skipToContent="#main-content"
                backgroundColor="white"
                paddingTop={[0, 4]}
                width={['100%', '100%', 'unset']}
                borderRadius={rem('10px')}
                mt="-69px"
              >
                <StickyHeader showMenu={showMenu} isMobileApp={isMobileApp}>
                  <StaySummary checkIn={nextDates.startDate} checkOut={nextDates.endDate} />
                  {!isLimited && (
                    <FilterWrapper>
                      <Box pr={2} width={['50%', '100%']}>
                        <Filter
                          data-testid="view-all-room-box"
                          onClick={handleViewAllRoom}
                          pr={4}
                          active={typeof roomTypeId !== 'string'}
                          alignItems="center"
                          width={('50%', 'auto')}
                          menuAlign="center"
                        >
                          View all rooms
                        </Filter>
                      </Box>
                      <Select
                        value={roomTypeId}
                        options={mappedExclusiveOfferRoomTypes}
                        placeholder={'Filter by rooms'}
                        width={('50%', 'auto')}
                        height={'48px'}
                        ellipsis
                        menuAlign="center"
                        onSelectedChange={onSelectedChange}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                      />
                    </FilterWrapper>
                  )}
                </StickyHeader>
                <Box px={[0, 10, 10]} pt={[0, 1, 1]} pb={[0, 6, 6]} data-testid="date-range-picker" backgroundColor="white">
                  <Calendar
                    minDate={minDate}
                    initialDisplayDate={initialDisplayDate}
                    monthsToDisplay={isLessThanBreakpoint(0) ? totalMonthsMobile : isLessThanBreakpoint(2) ? tabletNumberOfCalendars : 2}
                    startDate={nextDates.startDate}
                    endDate={nextDates.endDate}
                    onChangeDates={handleChangeDateRange}
                    focusedInput={focusedInput}
                    boundaries={boundaries}
                    maxSelectableDays={maxSelectableDays}
                    onMonthScrolled={onMonthScrolled}
                    isLimited={isLimited}
                  />
                </Box>
                {!isMobile && !isLimited && (
                  <Box px={8} py={6}>
                    <OfferTermsModal showModal={showModal} setShowModal={setShowModal} />
                  </Box>
                )}
                <StickyFooter>
                  {isMobile && !isLimited && (
                    <Box px={[4, 8]} py={6}>
                      <OfferTermsModal showModal={showModal} setShowModal={setShowModal} />
                    </Box>
                  )}
                  <Actions isMobileApp={isMobileApp}>
                    <CancelButton as={Link} onClick={onClearDates} data-testid="cancel-button">
                      Clear dates
                    </CancelButton>
                    {isValid() && (
                      <ConfirmButton onClick={submitModal} data-testid="done-button">
                        Confirm
                      </ConfirmButton>
                    )}
                  </Actions>
                </StickyFooter>
              </Dropdown>
            )}
          </Box>
        </>
      )}
    </ResponsiveModal>
  );
};

AvailabilityDatePicker.propTypes = {
  selectedDates: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  labelOptions: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  anchorX: PropTypes.oneOf(['left', 'right']),
  viewThreshold: PropTypes.number,
  submitOnChange: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  initialDisplayDate: PropTypes.instanceOf(Date),
  boundaries: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
  error: PropTypes.bool,
  maxSelectableDays: PropTypes.number,
  clearSelectedDates: PropTypes.func,
  isLimited: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isOptional: PropTypes.bool,
  extraMonthBreakpoint: PropTypes.number,
};

AvailabilityDatePicker.defaultProps = {
  selectedDates: {},
  labelOptions: {},
  anchorX: 'right',
  // viewThreshold: 0.5,
  submitOnChange: true,
  minDate: subDays(new Date(), 1),
  initialDisplayDate: new Date(),
  boundaries: {},
  error: false,
  maxSelectableDays: undefined,
  isLimited: false,
  backgroundColor: 'white',
  isOptional: false,
  extraMonthBreakpoint: 1100,
};

export default AvailabilityDatePicker;
